<template>
    <section>
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="card-title">
                <router-link to="/groupe">
                  <span class="float-right btn btn-info"> retour </span>
                </router-link>
              </div>
              <div class="container">
                <form @submit.prevent="updatergroupe()">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="first"
                          >name <span class="obligatoire">*</span>
                        </label>
  
                        <input
                          v-model="name"
                          type="text"
                          class="form-control"
                          placeholder=""
                          id="first"
                          required
                        />
                      </div>
                    </div>
  
                    <!--  col-md-6   -->
  
                    <div class="col-md-6">
                    <div class="form-group">
                      <label for="first"
                        >Choisir les membres <span class="obligatoire">*</span>
                      </label>

                      <v-select
                        :multiple="true"
                        v-model="user_id"
                        :closeOnClick="false"
                        :options="users"
                        :reduce="(users) => users"
                        label="name"
                      />
                    </div>
                  </div>
  
                    <!--  col-md-6   -->
  
                 
  
                   
  
                    <!--  col-md-6   -->
                  </div>
  
                  <button type="submit" class="btn btn-primary">
                    Modifier groupe
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  <script>
  import { HTTP } from "@/axios";
  import moment from "moment";
  export default {
    data() {
      return {
        
        name: "",
        user_id: [],
        users: [],
      };
    },
    created: function () {
      this.getgroupeById();
      this.getusers();

    },
    methods: {
        getusers() {
        HTTP.get("users/getusers")
          .then((response) => {
            this.users = response.data;
          })
          .then((err) => console.log(err));
      },
      
      getgroupeById() {
        HTTP.get("groupes/getgroupebyid/" + this.$route.params.id).then(
          (res) => {
            this.name = res.data.name;
            this.commentaire = res.data.commentaire;
            this.user_id = res.data.user_id;
          },
          (err) => {
            this.error = err.response.data.error;
          }
        );
      },
      updatergroupe() {
        const formData = new FormData();
       
        formData.append('_id', this.$route.params.id)
        formData.append('user_id', this.user_id)
        formData.append('name', this.name)
  
        // let groupe = {
        // prix: this.prix,
        // reference: this.reference,
        // description: this.description,
        // quantites: this.quantites,
        //  quantites_minimal:this.quantites_minimal,
        // categorie:this.categorie,
        // fournisseur:this.fournisseur,
        // designation:this.designation,&
        // prix:this.prix,
        // date_construction:this.date_construction,
        // date_peremption:this.date_peremption,
        // _id:this.$route.params.id
  
        // };
        HTTP.put("groupes/updategroupe/", formData).then(
          (res) => {
            if (res.status === 200) {
              Toast.fire({
                icon: "success",
                title: "groupe modifié",
              });
              this.$router.push("/groupe");
            }
          },
          (err) => {
            console.log(err);

            this.error = err.response.data.error;
          }
        );
      },
    },
  };
  </script>
  